<template>
  <div class="login__wrap">
    <div class="login__head">
      <el-image class="logoImg" :src="require('@/assets/img/logo.png')"></el-image>
    </div>
    <div class="login__bg">
      <div class="login__main">
        <div class="login-title">你有需求 我有空</div>
        <div class="login__box">
          <el-form
            class="sign__main"
            ref="elForm"
            :model="form.data"
            :rules="form.rules"
            @submit.prevent="submit"
          >
            <el-form-item prop="account">
              <el-input v-model.trim="form.data.account" clearable placeholder="请输入账号" prefix-icon="el-icon-user"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model.trim="form.data.password" clearable show-password placeholder="请输入密码" prefix-icon="el-icon-key"></el-input>
            </el-form-item>
            <el-form-item style="margin-top: 50px;">
              <el-button class="theneBtn" style="width: 100%;padding: 15px 40px;" type="primary" :loading="form.loading" :disabled="form.loading" @click.stop="submit">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="foot">-江西金手指科技有限公司-</div>
    </div>
  </div>
</template>
<script>
import {setStorage} from '@/storage/index.js'
export default {
  name: 'Login',
  data() {
    return {
      form: {
        data: {
          // account: '	18507085903',
          // password: '123456',
          account: '',
          password: '',
        },
        rules: {
          account: [{ required: true, message: '请输入用户名', trigger:'blur' }],
          password: [{ required: true, message: '请输入正确密码', trigger:'blur' }],
        }
      },
      secretCode:'', // 免密code
      secretToken: '', // 免密token
    }
  },
  created() {
    // 判断如果是湖口职教管理后台跳转过来的执行免密登录
    this.secretCode = this.$route.query.code;
    this.secretToken = this.$route.query.token;
    if(!!this.secretCode && !!this.secretToken) {
      this.freeSecret()
    }
  },
  methods: {
    // 免密登录
    freeSecret() {
      let _params = {
        code: this.secretCode,
        token: this.secretToken,
      }
      this.$http.post('/government/login/quickLogin', _params).then(res => {
        if(res.code === 1) {
          setStorage('token', res.data.token)
          setStorage('userName', res.data.name)
          setStorage('admin_id', res.data.admin_id)
          setStorage('role_type', res.data.role_type)
          setStorage('is_main', res.data.is_main)
          setStorage('city', res.data.city)
          setStorage('area', res.data.area)
          this.$router.push('home/index')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    submit() {
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          this.$http.post('/government/login/login', this.form.data).then(res => {
            if(res.code === 1) {
              setStorage('token', res.data.token)
              setStorage('userName', res.data.name)
              setStorage('admin_id', res.data.admin_id)
              setStorage('role_type', res.data.role_type)
              setStorage('is_main', res.data.is_main)
              setStorage('city', res.data.city)
              setStorage('area', res.data.area)
              this.$router.push('home/index')
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.login__wrap {
  width: 100%;
  height: 100vh;

  .login__head {
    height: 65px;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 60px;
  }

  .login__bg {
    height: calc(100vh - 65px);
    background-image: url('~@/assets/img/bg.png');
    background-size:cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .login__main {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .login-title {
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      color: #303133;
      margin-top: 0;
    }

    .login__box {
      width: 400px;
      padding: 40px;
      border-radius: 10px;
      background-color: #fffefe;
      box-sizing: border-box;
      overflow: hidden;
      border: solid 1px #eee;
      margin-top: 30px;
    }
    .theneBtn {
      background-color: $theme !important;
      border-color: $theme !important;
    }
  }

  .foot {
    margin-bottom: 40px;
  }
}
</style>
